.aboutUsNew {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
}
.aboutUsNew::-webkit-scrollbar {
  width: 0 !important;
}
.banner {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner4.png");
}
.main {
  position: relative;
  width: 1440px;
  margin: 0 auto;
}
.main h3 {
  position: relative;
  text-align: center;
  padding: 40px 0 40px;
  color: #333;
  font-size: 30px;
  font-weight: bold;
}
.main1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background: #FFF;
}
.companyProfile {
  padding-bottom: 60px;
}
.companyProfileInner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9FAFC;
  border-radius: 12px;
  line-height: 1.5;
}
.companyProfileInner img {
  width: 824px!important;
  height: 300px!important;
  border-radius: 12px;
}
.companyProfileInner p {
  font-size: 16px;
  color: #666;
  margin: 0 40px;
}
.businessArchitecture {
  margin-bottom: 60px;
}
.businessArchitectureLine {
  width: 75%;
  margin: 0 auto;
  display: flex;
  height: 60px;
}
.businessArchitectureLine > div {
  flex: 1;
  border: 1px solid #ddd;
  border-bottom: 0;
}
.businessArchitectureLine > div:nth-child(1) {
  border-right: 0;
}
.businessArchitectureLine > div:nth-child(3) {
  border-left: 0;
}
.businessArchitectureInner {
  display: flex;
  justify-content: center;
}
.businessArchitectureInner .businessArchitectureItem {
  flex: 1;
  background: #fff;
  transition: 0.5s;
  border-radius: 12px;
  margin-right: 30px;
  padding: 0 25px;
}
.businessArchitectureInner .businessArchitectureItem:last-child {
  margin-right: 0;
}
.businessArchitectureInner .businessArchitectureItem:hover {
  box-shadow: 0px 10px 30px 1px rgba(224, 228, 238, 0.5);
}
.businessArchitectureInner .businessArchitectureItem h4 {
  text-align: center;
  margin: 69px 0 30px;
  font-size: 20px;
  color: #333;
}
.businessArchitectureInner .businessArchitectureItem p {
  text-align: left;
  font-size: 16px;
  line-height: 1.4;
  color: #666;
  margin-bottom: 69px;
}
.invitationBar {
  text-align: center;
  background-image: url("../../assets/loginIndexNew/blueBgImg.png");
  background-position: center;
  background-size: cover;
  height: 260px;
}
.invitationBar .invitationBarInner {
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invitationBar .invitationBarInner .invitationBarItem {
  position: relative;
  flex: 1;
  color: #FFF;
}
.invitationBar .invitationBarInner .invitationBarItem:last-child:before {
  background: transparent;
}
.invitationBar .invitationBarInner .invitationBarItem:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 79px;
  background: #FFF;
  opacity: 0.5;
}
.invitationBar .invitationBarInner .invitationBarItem h4 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: 500;
}
.invitationBar .invitationBarInner .invitationBarItem h4 i {
  font-size: 16px;
  font-weight: normal;
}
.invitationBar .invitationBarInner .invitationBarItem p {
  font-size: 20px;
}
@media (max-width: 1500px) {
  .main {
    width: 1200px;
  }
  .banner {
    height: 250px;
  }
  .invitationBar .invitationBarInner {
    width: 1200px;
  }
  .companyProfileInner img {
    width: 687px!important;
    height: 250px!important;
  }
}
.clearfix:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.clearfix {
  zoom: 1;
}
